<template>
    <div class="mobile">手机端请使用微信搜索“收发猫”小程序进行快递/陪审信息登记</div>
    <div class="main">
        <div class="aside">
            <div class="send-menu">
                <div class="send-menu-title">寄件</div>
                <div class="send-menu-a"><a href="#/express">单个寄件</a></div>
            </div>
            <div class="send-list-menu">
                <div class="send-menu-title">寄件列表</div>
                <div class="send-menu-a"><a href="#/expresslists">寄件列表</a></div>
            </div>
            <div class="send-list-menu">
                <div class="send-menu-title">导出</div>
                <div class="send-menu-a"><a href="#/export">导出列表</a></div>
            </div>
            <div class="wx_qrcode">
                <img src="@/assets/qrcode.jpg" alt="">
            </div>
            <div class="footer">
                <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022002373号-2</a>
            </div>
        </div>
        <div class="content">
            <!-- 主寄件区域 -->
            <div class="send-area">
                <!-- 表单填写区域 -->
                <div class="send-form">
                    <div class="send-form-1">
                        <div><label for="expressNo">快递编号</label><input id="expressNo" type="text" placeholder="请输入快递编号" v-model="express.no"></div>
                        <div><label for="ah">案&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号</label><input id="ah" type="text" placeholder="请输入案号" v-model="express.ah"></div>
                    </div>
                    <div class="send-form-2">
                        <div style="width:178px;"><label for="destination">目&nbsp;的&nbsp;地</label><input id="destination" v-model="express.destination" type="text" placeholder="目&nbsp;的&nbsp;地" style="width:120px;"></div>
                        <div style="width:178px;"><label for="receiver">受送达人</label><input id="receiver" type="text" placeholder="受送达人" style="width:120px;" v-model="express.receiver"></div>
                        <div><label for="content">送达材料</label><input id="content" type="text" placeholder="请输入送达材料 / 类型" v-model="express.content"></div>
                    </div>
                    <div class="send-form-3">
                        <div><label for="sendPeople">寄件人</label><input id="sendPeople" type="text" placeholder="寄件人" v-model="express.sendPeople"></div>
                    </div>
                    <!-- <div class="send-form-4">
                        <div><label for="deliverer">接&nbsp;件&nbsp;人&nbsp;</label><input id="deliverer" type="text" placeholder="接件人" disabled></div>
                        <div><label for="deliverTime">接收时间</label><input id="deliverTime" type="text" placeholder="接收时间" disabled></div>
                    </div> -->
                </div>
                <button class="send-btn" @click="sendExpress">寄件</button>
            </div>
            <!-- <div>
                <form action="http://127.0.0.1:8000/api/upload" method="post" enctype="multipart/form-data">
                    <input type="file" name="img">
                    <input type="submit" value="Upload">
                </form>
            </div> -->
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
            express:{
                no:'',
                ah:'(2024)云2328',
                destination:'',
                receiver:'',
                content:'',
                sendPeople:'',
            }
        }
    },
    methods:{
        sendExpress(){
            if (this.express.no != '' && this.express.sendPeople != '' && this.express.fillPeople != ''){
                this.axios({
                    method:'post',
                    headers:{Authorization:localStorage.token},
                    url:'https://kmail.esia.asia/express/v1/addexpress',
                    data:{
                        expressno:this.express.no,
                        ah:this.express.ah,
                        destination:this.express.destination,
                        receiver:this.express.receiver,
                        content:this.express.content,
                        sendpeople:this.express.sendPeople,
                    }
                }).then(res=>{
                    if(res.data.status == 1){
                        this.express.no = '',
                        this.express.ah = '',
                        this.express.destination = '',
                        this.express.receiver = '',
                        this.express.content = '',
                        this.express.sendPeople = ''
                    }else if (res.data.status == -1){
                        alert(res.data.msg)
                    }else{alert(res.data.msg)}
                }).catch(err=>{console.log(err);})
            }else{
                alert('请填入必填项')
            }
        }
    },
}
</script>


<style scoped>
.mobile{
    display: none;
}
input{
    height:34px;
    width:200px;
    line-height:26px;
    font-size: 1em;
    outline:none;
    border:none;
    border-radius: 6px;
    border:1px solid #cfcfcf;
    padding:4px;
    margin:4px;
    text-align: center;
}
label{
    color:rgb(106, 106, 106);
}
.main{
    height:94vh;
    display:flex;
    flex-direction:row;
    background-color: #f2f3f5;
}
.aside{
    width:10vw;
    margin-right:2vw;
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    align-content:space-around;
    text-align: left;
}
.footer{
    font-size: 12px;
    width: inherit;
    text-align: center;
  position: absolute;
  bottom: 0;
  /* margin-bottom: 10px; */
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer a{
  text-decoration: none;
}
.footer a:visited{
  color:#aeaeae
}
.aside>div{
    margin-bottom: 20px;
    height:10vh;
    margin-left: 20px;
    /* background-color: aquamarine; */
}
.send-menu-title{
    color:rgb(106, 106, 106)
}
.send-menu-a{
    margin-top:10px;
    width:10vw;
    height:56px;
    line-height: 56px;
    font-size: 1.2rem;
    background-color:#9f9f9f61;
    border-radius: 6px;
}
.send-menu-a a{
    outline:none;
    text-decoration: none;
    color:#333;
}
.content{
    width: 82vw;
    border-radius: 10px 10px 0 0 ;
    margin-right:2vw;
    background-color: white;
}
.send-form{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.send-form-1,.send-form-2,.send-form-3,.send-form-4{
    width:24%;
    margin:20px 6px 6px 6px;
    background-color:#e4e4e440;
    border-radius:10px;
}
.send-form-2{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
    justify-content: space-around;
    align-items: center;
}
#content{
    width:234px;
}
.send-form-3{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;
}
.send-form-3 div{
    width:10vw;
}
.send-form-4{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.send-form-4 div{
    width:80%;
}
.send-form-4 input{
    width:50%;
}
#expressNo,#sendPeople,#fillPeople{
    border:1px solid #ff4d00bc
}
#deliverer,#deliverTime{
    background-color: #cfcfcf51;
    cursor:not-allowed;
}
.send-btn{
    width:200px;
    height:40px;
    border:none;
    background-color:rgb(0, 106, 255);
    font-size: 1.2em;
    color:white;
    border-radius: 6px;
}
.send-btn:hover{
    cursor:pointer;
}
.wx_qrcode{
    display: inline-block;
    box-sizing: border-box;
    width:80%;
    height:auto;
}
.wx_qrcode img{
    width: 80%;
    height: auto;
}

/* 手机端 */
@media (max-width: 768px) {
.main{
    display: none;
}
.mobile{
    display: block;
    position: fixed;
    width:100vw;
    height:100vh;
    left: 0;
    top: 50%;
    text-align: center;
}
}

/* 适配1200px宽度以上 */
@media (min-width: 1200px){
.mobile{
    display: none;
}
input{
    height:34px;
    width:200px;
    line-height:26px;
    font-size: 1em;
    outline:none;
    border:none;
    border-radius: 6px;
    border:1px solid #cfcfcf;
    padding:4px;
    margin:4px;
    text-align: center;
}
label{
    color:rgb(106, 106, 106);
}
.main{
    height:94vh;
    display:flex;
    flex-direction:row;
    background-color: #f2f3f5;
}
.aside{
    width:10vw;
    margin-right:2vw;
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    align-content:space-around;
    text-align: left;
}
.footer{
    font-size: 12px;
    width: inherit;
    text-align: center;
  position: absolute;
  bottom: 0;
  /* margin-bottom: 10px; */
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer a{
  text-decoration: none;
}
.footer a:visited{
  color:#aeaeae
}
.aside>div{
    margin-bottom: 20px;
    height:10vh;
    margin-left: 20px;
    /* background-color: aquamarine; */
}
.send-menu-title{
    color:rgb(106, 106, 106)
}
.send-menu-a{
    margin-top:10px;
    width:10vw;
    height:56px;
    line-height: 56px;
    font-size: 1.2rem;
    background-color:#9f9f9f61;
    border-radius: 6px;
}
.send-menu-a a{
    outline:none;
    text-decoration: none;
    color:#333;
}
.content{
    flex:1;
    border-radius: 10px 10px 0 0 ;
    margin-right:2vw;
    background-color: white;
}
.send-form{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.send-form-1,.send-form-2,.send-form-3,.send-form-4{
    width:25%;
    margin:20px 6px 6px 6px;
}
.send-form-1{
    display: flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content: center;
    align-items: center;
}
.send-form-1 div{
    width:20vw;
    text-align:center;
}
.send-form-2{
    display: flex;
    flex-direction: row;
    flex-wrap:wrap;
}
.send-form-2 div{
    width:20vw;
    text-align:center;
}
.send-form-3{
    display: flex;
    flex-direction: column;
    width:16%;
}
.send-form-3 *{
     width:100px;
}
.send-form-4 div{
    width:20vw;
    text-align:center;
}
.send-btn{
    width:200px;
    height:40px;
    border:none;
    background-color:rgb(0, 106, 255);
    font-size: 1.2em;
    color:white;
    border-radius: 6px;
}
.send-btn:hover{
    cursor:pointer;
}
.wx_qrcode{
    display: inline-block;
    box-sizing: border-box;
    width:80%;
    height:auto;
}
.wx_qrcode img{
    width: 80%;
    height: auto;
}
}
</style>