import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/components/Home.vue'
import Login from '../views/LoginView.vue'
import Register from '../views/RegisterView.vue'
import Assessor from '@/components/Assessor.vue'
import AddExpress from '@/components/AddExpress.vue'
import ExpressLists from '@/components/ExpressLists.vue'
import ExportList from '@/components/Export.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children:[
    {
      path:'/express',
      component: AddExpress,
    },{
      path: '/expresslists',
      component: ExpressLists
    },{
      path: '/export',
      component: ExportList
    },
    {
      path:'/assessor',
      component: Assessor,
    }],
    redirect:'/login'
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:Login,
  },{
    path: '/register',
    name: 'register',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:Register,
  },{ path:'/assessor',
    // name:'assessor',
    component:Assessor,
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
