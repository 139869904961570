<template>
    <div class="main">
        <div class="aside">
            <div class="send-menu">
                <div class="send-menu-title">寄件</div>
                <div class="send-menu-a"><a href="#/express">单个寄件</a></div>
            </div>
            <div class="send-list-menu">
                <div class="send-menu-title">寄件列表</div>
                <div class="send-menu-a"><a href="#/expresslists">寄件列表</a></div>
            </div>
            <div class="send-list-menu">
                <div class="send-menu-title">导出</div>
                <div class="send-menu-a"><a href="#/export">导出列表</a></div>
            </div>
            <div class="go-to-sign" v-if="checked.length!=0">
                <!-- <div class="send-menu-title">寄件列表</div> -->
                <div class="send-menu-a">
                    <button @click="creatCode">发起签名</button>
                    <button @click="cleanCheck">清空选择</button>
                </div>
            </div>
            <div class="wx_qrcode">
                <img src="@/assets/qrcode.jpg" alt="">
            </div>
            <div class="ba">
                <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022002373号-2</a>
            </div>
        </div>
        <div class="content">
            <!-- <div class="content-header">
                <table style="width:auto;">
                    <tr class="search-header">
                        <th class="checkbox"></th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="快递编号">
                        </th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="案号">
                        </th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="目的地">
                        </th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="受送达人">
                        </th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="送达材料">
                        </th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="寄件人">
                        </th>
                        <th class="">
                            <input type="text" name="" id="" placeholder="填发人">
                        </th>
                    </tr>
                </table>
            </div> -->
            <div class="list-header">
                <table class="tables">
                    <tr class="table-header">
                        <th class="checkbox"></th>
                        <th class="express-no">快递编号</th>
                        <th class="ah">案号</th>
                        <th class="destination">目的地</th>
                        <th class="receiver">受送达人</th>
                        <th class="sdcontent">送达材料</th>
                        <th class="sendpeople">寄件人</th>
                        <th class="fillpeople">填发人</th>
                        <th class="delivertime">填发时间</th>
                        <!-- <th class="deliverer">接件人</th> -->
                        <th class="sign">签收签名</th>
                        <!-- <th class="delivertime">接件时间</th> -->
                    </tr>
                    <tr class="table-header-2">
                        <!-- <th class="checkbox-2"></th> -->
                        <th class="express-no-2">
                            <input type="text" name="" id="" placeholder="快递编号" v-model.trim="search_data.expressno">
                        </th>
                        <th class="ah-2">
                            <input type="text" name="" id="" placeholder="案号" v-model.trim="search_data.ah">
                        </th>
                        <th class="destination-2">
                            <input type="text" name="" id="" placeholder="目的地" v-model.trim="search_data.destination">
                        </th>
                        <th class="receiver-2">
                            <input type="text" name="" id="" placeholder="受送达人" v-model.trim="search_data.receiver">
                        </th>
                        <th class="sdcontent-2">
                            <input type="text" name="" id="" placeholder="送达材料" v-model.trim="search_data.content">
                        </th>
                        <th class="sendpeople-2">
                            <input type="text" name="" id="" placeholder="寄件人" v-model.trim="search_data.sendpeople">
                        </th>
                        <th class="fillpeople-2">
                            <input type="text" name="" id="" placeholder="填发人" v-model.trim="search_data.fillpeople">
                        </th>
                        <!-- <th class="delivertime"><input type="text" name="" id="" placeholder="填发时间"></th>
                        <th class="deliverer"><input type="text" name="" id="" placeholder="快递编号"></th> -->
                        <button style="width:180px" @click="doSearch">搜索</button>
                        <button class="clearSearch" style="width:100px" @click="clearSearch">清除搜索条件</button>
                    </tr>
                    <tr v-for="(i,index) in expressLists" :key="index" class="single-row">
                        <td><span style="width:30px; height:30px">
                            <input type="checkbox" :value="i.id" v-model="checked" class="checkbox">
                        </span></td>
                        <td class="express-no">{{i.expressno}}</td>
                        <td class="ah">{{i.ah}}</td>
                        <td class="destination">{{i.destination}}</td>
                        <td class="receiver">{{i.receiver}}</td>
                        <td class="sdcontent">{{i.content}}</td>
                        <td class="sendpeople">{{i.sendpeople}}</td>
                        <td class="fillpeople">{{i.fillpeople}}</td>
                        <td class="delivertime">{{formatTime(i.created_time)}}</td>
                        <!-- <td class="deliverer">{{i.deliverer}}</td> -->
                        <td class="sign"><img :src="sign(i.sign)" alt="" v-if="i.sign != null"></td>
                        <!-- <td class="delivertime">{{deliverTime(i.updated_time,i.deliverer)}}</td> -->
                    </tr>
                </table>
            </div>
            <div class="footer">
                <div class="footer-buttons">
                    <div style="width:8vw">
                        <button v-if="previousPage" @click="pre">上一页</button>
                    </div>
                    <div style="width:8vw">
                        <button v-if="nextPage" @click="next">下一页</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="qrcode_out" v-if="show_qrcode" @click="cleanQrCode">
        <div class="code4">{{code}}</div>
    </div>
    <div class="qrcode" ref="qrCodeUrl" @click="cleanQrCode"></div>
</template>

<script>
// 引入QRCode程序
import QRCode from 'qrcodejs2'
const Base64 = require('js-base64').Base64
export default{
    data(){
        return{
            code:'',
            expressLists:{},
            checked:[],
            show_qrcode:false,
            previousPage:'',
            nextPage:'',
            search_data:{
                expressno:'',
                ah:'',
                destination:'',
                receiver:'',
                content:'',
                sendpeople:'',
                fillpeople:'',
            }
        }
    },
    methods:{
        formatTime(value){
            var v = value
            return v.slice(0,4) + '年' + v.slice(5,7)+ '月' + v.slice(8,10) + '日' + v.slice(11,19)
        },
        deliverTime(value,deliverer){
            if (deliverer ==null){
                return ""
            }else{
            var v = value
            return v.slice(0,4) + '年' + v.slice(5,7)+ '月' + v.slice(8,10) + '日' + v.slice(11,19)}
        },
        sign(v){
            if (v != null){
                return 'https://kmail.esia.asia' + v
            }
        },
        checkbox(){
            // console.log(this.checked);
        },
        // 创建扫描二维码
        creatQrCode(){
            var that = this
            this.show_qrcode = true
            console.log(that.checked)
            var qrcode = new QRCode(this.$refs.qrCodeUrl, {
                text: String(list), // 需要转换为二维码的内容
                width: 800,
                height: 800,
                colorDark: '#000000',
                colorLight: '#ffffff',
                correctLevel: QRCode.CorrectLevel.H
            })
        },
        // 关闭全屏弹窗、清空二维码
        cleanQrCode(){
            this.show_qrcode = false;
            this.$refs.qrCodeUrl.innerHTML = ""
        },
        // 清除选择项
        cleanCheck(){
            this.checked = []
        },
        // 选择内容base64提交后端获取code
        creatCode(){
            let _ = this
            console.log(_.checked)
            let list = Base64.encode(_.checked)
            console.log(this.checked)
            this.axios({
                method: 'post',
                headers:{Authorization:localStorage.token},
                url : 'https://kmail.esia.asia/express/v1/createcode',
                data:{
                    encodeList: list,
                    source:'pc'
                }
            }).then(res=>{
                if (res.data.status == 1){
                    this.show_qrcode = true
                    _.code = res.data.code
                }else{
                    alert(res.data)
                }
                }).catch(err=>{console.log(err)});
        },
        next(){
            if (this.search_data.expressno||this.search_data.ah||this.search_data.destination||this.search_data.receiver||this.search_data.content||this.search_data.sendpeople||this.search_data.fillpeople){
                this.axios({
                    headers:{Authorization:localStorage.token},
                    url:this.nextPage
                    }).then(res=>{
                        this.expressLists = res.data.results
                        this.nextPage = res.data.next
                        this.previousPage = res.data.previous
                        }).catch(err=>{console.log(err)})
            }else{
                this.axios({
                    method:'post',
                    headers:{Authorization:localStorage.token},
                    url:this.nextPage
                    }).then(res=>{
                        this.expressLists = res.data.results
                        this.nextPage = res.data.next
                        this.previousPage = res.data.previous
                        }).catch(err=>{console.log(err)})
            }
        },
        pre(){
            if (this.search_data.expressno||this.search_data.ah||this.search_data.destination||this.search_data.receiver||this.search_data.content||this.search_data.sendpeople||this.search_data.fillpeople){
                this.axios({
                    headers:{Authorization:localStorage.token},
                    url:this.previousPage
                    }).then(res=>{
                        this.expressLists = res.data.results
                        this.nextPage = res.data.next
                        this.previousPage = res.data.previous
                        }).catch(err=>{console.log(err)})
            }else{
                this.axios({
                    method:'post',
                    headers:{Authorization:localStorage.token},
                    url:this.previousPage
                    }).then(res=>{
                        this.expressLists = res.data.results
                        this.nextPage = res.data.next
                        this.previousPage = res.data.previous
                        }).catch(err=>{console.log(err)})
            }
        },
        doSearch(){
            if (this.search_data.expressno||this.search_data.ah||this.search_data.destination||this.search_data.receiver||this.search_data.content||this.search_data.sendpeople||this.search_data.fillpeople){
                this.axios({
                    headers:{Authorization:localStorage.token},
                    url:'https://kmail.esia.asia/express/v1/search',
                    params:{
                        expressno:this.search_data.expressno,
                        ah:this.search_data.ah,
                        destination:this.search_data.destination,
                        receiver:this.search_data.receiver,
                        content:this.search_data.content,
                        sendpeople:this.search_data.sendpeople,
                        fillpeople:this.search_data.fillpeople
                    }
                    }).then(res=>{
                        this.expressLists = res.data.results
                        this.nextPage = res.data.next
                        this.previousPage = res.data.previous
                        }).catch(err=>{console.log(err)})
            }else{
                return
            }
        },
        clearSearch(){
            this.search_data = {
                expressno:'',
                ah:'',
                destination:'',
                receiver:'',
                content:'',
                sendpeople:'',
                fillpeople:'',
            }
            this.axios({
                method:'post',
                headers:{Authorization:localStorage.token},
                url:'https://kmail.esia.asia/express/v1/showexpress'
                }).then(res=>{
                    this.expressLists = res.data.results
                    this.nextPage = res.data.next
                    this.previousPage = res.data.previous
                    }).catch(err=>{console.log(err)})
        }
    },
    mounted(){
        this.axios({
            method:'post',
            headers:{Authorization:localStorage.token},
            url:'https://kmail.esia.asia/express/v1/showexpress'
        }).then(res=>{
            this.expressLists = res.data.results
            this.nextPage = res.data.next
            this.previousPage = res.data.previous
            }).catch(err=>{console.log(err)})
    }
}
</script>

<style scoped>
.main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #f2f3f5;
    max-height: 94vh;
}
.aside{
    min-width: 10vw;
    max-width: 12vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.ba{
    font-size: 12px;
    width: inherit;
    text-align: center;
  position: absolute;
  bottom: 0;
  /* margin-bottom: 10px; */
  display:flex;
  flex-direction: column;
  align-items: center;
}
.ba a{
  text-decoration: none;
}
.ba a:visited{
  color:#aeaeae
}
.send-menu,.send-list-menu{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-left: 20px;
    width:8vw;
}
.send-menu-title{
    color:rgb(106, 106, 106)
}
.send-menu-a{
    background-color: #9f9f9f61;
    height:56px;
    line-height: 56px;
    font-size: 1.2rem;
    border-radius: 6px;
    margin-top: 10px;
    width:inherit;
    text-align:left
}
.send-menu-a a:visited{
    outline: none;
    text-decoration: none;
    color:#333333
}
.send-menu-a a{
    outline: none;
    text-decoration: none;
    color:#333333
}
.content{
    flex:1;
    height: 94vh;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
}
/* .content-header{
    height:4vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
} */
.search-header{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items:center;
    flex:1;
    position: sticky;
    top:0;
    left: 0;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
}
/* .search{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
} */
.list-header{
    height: 90vh;
    overflow-y:auto;
}
.footer{
    height:4vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.footer-buttons{
    background-color: #f2f3f5;
    border-radius: 20px;
    width:20vw;
    height: inherit;
    display:flex;
    align-items: center;
    justify-content: center;
}
.footer-buttons button{
    width:8vw;
    height: inherit;
}
.tables{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
}
tr:nth-child(even){
    background-color: #f2f3f5;
}
tr{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    /* border-bottom: 1px solid #777777; */
}
.single-row:hover{
    background-color: #c3ecff;
    transition: background-color 0.1s;
}
.table-header{
    position: sticky;
    top:0;
    right: 0;
    min-height: 40px;
    background-color: #ffffff;
}
.table-header-2{
    display:flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: center;
    position: sticky;
    top:40px;
    right: 0;
    min-height: 40px;
    background-color: #ffffff;
}
.checkbox{
    box-sizing: border-box;
    text-align: center;
    width: 30px;
    height: 30px;
    border:none;
    border-radius: 0;
    margin: 0;
    padding: 0;
}
.checkbox-2{
    box-sizing: border-box;
    text-align: center;
    width: 15px;
    height: 15px;
    border:none;
    border-radius: 0;
    margin: 0;
    padding: 0;
}
.express-no{
    width:120px
}
.express-no-2 input{
    text-align: center;
    margin-left: 40px;
    width:140px
}
.ah{
    width:240px
}
.ah-2 input{
    text-align: center;
    width:180px
}
.destination{
    width:140px
}
.destination-2 input{
    text-align: center;
    width:140px
}
.receiver{
    width:140px
}
.receiver-2 input{
    text-align: center;
    width:100px
}
.sdcontent{
    width:140px
}
.sdcontent-2 input{
    text-align: center;
    width:100px
}
.sendpeople{
    width:80px
}
.sendpeople-2 input{
    text-align: center;
    width:60px
}
.fillpeople{
    width:80px
}
.fillpeople-2 input{
    text-align: center;
    width:60px
}
.delivertime{
    width:200px
}
.deliverer{
    width:80px
}
.go-to-sign{
    text-align: center;
    margin:0 auto;
}
.sign{
    width:120px
}
.sign img{
    max-width:100px;
}
.qrcode_out{
    position: fixed;
    background-color:rgba(107, 107, 107, 0.705);
    left:0;
    right: 0;
    top:0;
    bottom: 0;
    margin-left:-100px;
    margin-top:-100px;
    border-radius: 6px;
    z-index: 9990;
    display:flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.qrcode{
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
    width:100px;
    height:100px;
    /* background-color: #000; */
    z-index: 9999;
    border-radius: 10px;
}
.wx_qrcode{
    width:inherit;
    height:inherit;
}
.wx_qrcode img{
    width: 80%;
    height: inherit;
}
.code4{
    text-align: center;
    font-size: 100px;
    background-color: #f2f3f5;
    width:300px;
    height:150px;
    border-radius: 50px;
}
.clearSearch{
    background-color: lightcoral;
    color:#ffffff;
    outline: none;
    border: 1px solid lightcoral;
}
.clearSearch:hover{
    background-color: rgb(234, 101, 101);
}
/* .list-header tr{
    display:flex;
    flex-direction:row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}
.express-no,.ah{
    width:150px;
}
.destination{
    width:80px;
    margin-left: 20px;;
}
.receiver,.sendpeople,.fillpeople,.deliverer{
    width:80px;
}
.sdcontent{
    width:80px
}
.delivertime{
    width:200px;
}
.sign img{
    width: 100px;
    height:60px
} */
</style>