<template>
  <div class="top" v-if="display">
    <div class="confirm">
        <div style="margin-top:1em;margin-bottom:1em;">完善个人信息</div>
        <div class="confirm-main">
            <label for="name"></label>
            <input type="text" placeholder="请输入姓名" v-model.trim="name">
            <div>
                <button @click="confirm_name">确定</button>
            </div>
        </div>
    </div>
  </div>
  <div class="header">
    <div class="header-left"></div>
    <div class="header-menu">
      <router-link to="/express">快递系统</router-link> ｜ 
      <router-link to="/assessor">陪审系统</router-link>
    </div>
    <div class="header-right" v-if="isLogin">
      <div @click="modifyuserinfo">{{name}}</div>
      <button class="logout" @click="logout" style="margin-left:2em;">退出</button>
    </div>
    <div class="header-right" v-else>
      <router-link to="/login">登陆 / 注册</router-link>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
export default {
  name: "home",
  data(){
    return{
      name:'',
      display:true,
      isLogin:false
    }
  },
  methods:{
    confirm_name(){
      this.axios({
        method:'post',
        headers:{Authorization:localStorage.token},
        url:'https://kmail.esia.asia/user/v1/modifyuserinfo',
        data:{
            name:this.name
        }
      }).then(res=>{
        if(res.data.status == 1){
            this.display = false;
            localStorage.name = this.name;
        }
      }).catch(err=>{console.log(err)})
    },
    logout(){
      localStorage.clear()
      this.$router.push({
          path:'/login'
      })
    },
    modifyuserinfo(){
      this.display = true;
    },
  },
  mounted(){
    if(localStorage.token){
      this.isLogin = true
      this.name = localStorage.name
      if (this.name == (''||undefined)){
          this.display = true
      }else{
          this.display = false
      }
    }else{
      this.$router.push({
          path:'/login'
      })
    }
  },
};
</script>

<style scoped>

.top{
    display:flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 9999;
    color:black;
    background-color:rgba(0, 0, 0, 0.7);
}
.confirm{
    display:flex;
    flex-direction:column;
    background-color:#dfdfdf;
    width: 20%;
    height:26%;
    border-radius: 0.4em;
    animation-name:shadow;
    animation-duration:1s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-timing-function:linear;
}
@keyframes shadow {
  0% {
    box-shadow: 0px -10px 40px #ea5554,/* 上外 */
                -10px 0px 40px #acfb73,/* 左外 */
                10px 0px 40px #8254f5,/* 右外 */
                0px 10px 40px #87f9f9;/* 下外 */
  }
  25% {
    box-shadow: 0px -10px 40px #acfb73,/* 上 */
                -10px 0px 40px #87f9f9,/* 左 */
                10px 0px 40px #ea5554,/* 右 */
                0px 10px 40px #8254f5;/* 下 */
  }
  50% {
    box-shadow: 0px -10px 40px #87f9f9,/* 上 */
                -10px 0px 40px #8254f5,/* 左 */
                10px 0px 40px #acfb73,/* 右 */
                0px 10px 40px #ea5554;/* 下 */
  }
  75% {
    box-shadow: 0px -10px 40px #8254f5,/* 上 */
                -10px 0px 40px #ea5554,/* 左 */
                10px 0px 40px #87f9f9,/* 右 */
                0px 10px 40px #acfb73;/* 下 */
  }
  100% {
    box-shadow: 0px -10px 40px #ea5554,/* 上 */
                -10px 0px 40px #acfb73,/* 左 */
                10px 0px 40px #8254f5,/* 右 */
                0px 10px 40px #87f9f9;/* 下 */
  }
}
.confirm-main input{
    font-size: 1em;
    text-align: center;
    width:50%;
    border:0;
    height:2em;
    outline: none;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}
.confirm-main input:hover{
    font-size: 1em;
    text-align: center;
    width:60%;
    border:0;
    height:2em;
    box-shadow:0 0 40px white;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}
.confirm-main input:focus{
    font-size: 1em;
    text-align: center;
    width:60%;
    border:0;
    height:2em;
    box-shadow:0 0 40px white;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}

.confirm-main button{
    margin:0;
    padding:0;
    font-size: 1.2em;
    outline:none;
    background-color:#dfdfdf;
    border-radius: 0.3em;
    border:none;
    background-color: skyblue;
    width:50%;
    height:42px;
    transition:0.4s;
}
.confirm-main button:hover{
    margin:0;
    padding:0;
    background-color:#dfdfdf;
    border-radius: 0.3em;
    background-color: skyblue;
    box-shadow:0 0 40px skyblue;
    color:white;
    width:60%;
    transition:0.4s;
}
.confirm-main button:active{
    margin:0;
    padding:0;
    border-radius: 0.3em;
    color:rgb(136, 136, 136);
    background-color: skyblue;
    box-shadow:0 0 0px skyblue;
    width:50%;
    transition:0.1s;
}
.header{
  height:6vh;
  width:100%;
  background-color: #f2f3f5;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.header-left{
  flex:1;
}
.header-menu{
  flex:1 1 46vw;
}
.header-right{
  flex:1;
  display:flex;
  flex-direction:row;
}
.logout{
  width:100px;
  outline:none;
  background-color:#ff5900d3;
  border:none;
  color:white;
  font-size: 1em;
  transition:0.2s;
  border-radius:6px;
}
.logout:hover{
  box-shadow: 0 0 10px #ff5900d3;
  transition:0.2s;
}
a{
  text-decoration: none;
  font-size: 1.2em;
  transition:0.2s;
  color:black;
}
a:hover{
  cursor:pointer;
  color:black;
  border-bottom: 2px solid black;
  transition: 0.2s;
}
a:visited{
  color:black
}

/* 小尺寸屏幕适配 */
@media (max-width: 768px) {
.top{
    display:flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    width:100vw;
    height:100vh;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 9999;
    color:black;
    background-color:rgba(0, 0, 0, 0.7);
}
.confirm{
    display:flex;
    flex-direction:column;
    background-color:#dfdfdf;
    justify-content: center;
    width: 100%;
    height:100%;
    border-radius: 0.4em;
    animation-name:shadow;
    animation-duration:1s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-timing-function:linear;
}
@keyframes shadow {
  0% {
    box-shadow: 0px -10px 40px #ea5554,/* 上外 */
                -10px 0px 40px #acfb73,/* 左外 */
                10px 0px 40px #8254f5,/* 右外 */
                0px 10px 40px #87f9f9;/* 下外 */
  }
  25% {
    box-shadow: 0px -10px 40px #acfb73,/* 上 */
                -10px 0px 40px #87f9f9,/* 左 */
                10px 0px 40px #ea5554,/* 右 */
                0px 10px 40px #8254f5;/* 下 */
  }
  50% {
    box-shadow: 0px -10px 40px #87f9f9,/* 上 */
                -10px 0px 40px #8254f5,/* 左 */
                10px 0px 40px #acfb73,/* 右 */
                0px 10px 40px #ea5554;/* 下 */
  }
  75% {
    box-shadow: 0px -10px 40px #8254f5,/* 上 */
                -10px 0px 40px #ea5554,/* 左 */
                10px 0px 40px #87f9f9,/* 右 */
                0px 10px 40px #acfb73;/* 下 */
  }
  100% {
    box-shadow: 0px -10px 40px #ea5554,/* 上 */
                -10px 0px 40px #acfb73,/* 左 */
                10px 0px 40px #8254f5,/* 右 */
                0px 10px 40px #87f9f9;/* 下 */
  }
}
.confirm-main input{
    font-size: 1em;
    text-align: center;
    width:50%;
    border:0;
    height:2em;
    outline: none;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}
.confirm-main input:hover{
    font-size: 1em;
    text-align: center;
    width:60%;
    border:0;
    height:2em;
    box-shadow:0 0 40px white;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}
.confirm-main input:focus{
    font-size: 1em;
    text-align: center;
    width:60%;
    border:0;
    height:2em;
    box-shadow:0 0 40px white;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}

.confirm-main button{
    margin:0;
    padding:0;
    font-size: 1.2em;
    outline:none;
    background-color:#dfdfdf;
    border-radius: 0.3em;
    border:none;
    background-color: skyblue;
    width:50%;
    height:42px;
    transition:0.4s;
}
.confirm-main button:hover{
    margin:0;
    padding:0;
    background-color:#dfdfdf;
    border-radius: 0.3em;
    background-color: skyblue;
    box-shadow:0 0 40px skyblue;
    color:white;
    width:60%;
    transition:0.4s;
}
.confirm-main button:active{
    margin:0;
    padding:0;
    border-radius: 0.3em;
    color:rgb(136, 136, 136);
    background-color: skyblue;
    box-shadow:0 0 0px skyblue;
    width:50%;
    transition:0.1s;
}
.header{
  height:6vh;
  width:100%;
  background-color: #f2f3f5;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.header-left{
  flex:1;
}
.header-menu{
  flex:1 1 46vw;
}
.header-right{
  flex:1;
  display:flex;
  flex-direction:row;
}
.logout{
  width:100px;
  outline:none;
  background-color:#ff5900d3;
  border:none;
  color:white;
  font-size: 1em;
  transition:0.2s;
  border-radius:6px;
}
.logout:hover{
  box-shadow: 0 0 10px #ff5900d3;
  transition:0.2s;
}
a{
  text-decoration: none;
  font-size: 1.2em;
  transition:0.2s;
  color:black;
}
a:hover{
  cursor:pointer;
  color:black;
  border-bottom: 2px solid black;
  transition: 0.2s;
}
a:visited{
  color:black
}
}
@media (min-width:1200px) {
.top{
    display:flex;
    justify-content: center;
    align-items: center;
    position:fixed;
    width:100%;
    height:100%;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 9999;
    color:black;
    background-color:rgba(0, 0, 0, 0.7);
}
.confirm{
    display:flex;
    flex-direction:column;
    background-color:#dfdfdf;
    width: 20%;
    height:26%;
    border-radius: 0.4em;
    animation-name:shadow;
    animation-duration:10s;
    animation-iteration-count:infinite;
    animation-direction:normal;
    animation-timing-function:linear;
}
@keyframes shadow {
  0% {
    box-shadow: 0px -10px 40px #ea5554,/* 上外 */
                -10px 0px 40px #acfb73,/* 左外 */
                10px 0px 40px #8254f5,/* 右外 */
                0px 10px 40px #87f9f9;/* 下外 */
  }
  25% {
    box-shadow: 0px -10px 40px #acfb73,/* 上 */
                -10px 0px 40px #87f9f9,/* 左 */
                10px 0px 40px #ea5554,/* 右 */
                0px 10px 40px #8254f5;/* 下 */
  }
  50% {
    box-shadow: 0px -10px 40px #87f9f9,/* 上 */
                -10px 0px 40px #8254f5,/* 左 */
                10px 0px 40px #acfb73,/* 右 */
                0px 10px 40px #ea5554;/* 下 */
  }
  75% {
    box-shadow: 0px -10px 40px #8254f5,/* 上 */
                -10px 0px 40px #ea5554,/* 左 */
                10px 0px 40px #87f9f9,/* 右 */
                0px 10px 40px #acfb73;/* 下 */
  }
  100% {
    box-shadow: 0px -10px 40px #ea5554,/* 上 */
                -10px 0px 40px #acfb73,/* 左 */
                10px 0px 40px #8254f5,/* 右 */
                0px 10px 40px #87f9f9;/* 下 */
  }
}
.confirm-main input{
    font-size: 1em;
    text-align: center;
    width:50%;
    border:0;
    height:2em;
    outline: none;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}
.confirm-main input:hover{
    font-size: 1em;
    text-align: center;
    width:60%;
    border:0;
    height:2em;
    box-shadow:0 0 40px white;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}
.confirm-main input:focus{
    font-size: 1em;
    text-align: center;
    width:60%;
    border:0;
    height:2em;
    box-shadow:0 0 40px white;
    border-radius: 0.3em;
    margin-bottom: 1.5em;
    transition:0.2s;
}

.confirm-main button{
    margin:0;
    padding:0;
    background-color:#dfdfdf;
    border-radius: 0.3em;
    background-color: skyblue;
    width:50%;
    transition:0.4s;
}
.confirm-main button:hover{
    margin:0;
    padding:0;
    background-color:#dfdfdf;
    border-radius: 0.3em;
    background-color: skyblue;
    box-shadow:0 0 40px skyblue;
    color:white;
    width:60%;
    transition:0.4s;
}
.confirm-main button:active{
    margin:0;
    padding:0;
    border-radius: 0.3em;
    color:rgb(136, 136, 136);
    background-color: skyblue;
    box-shadow:0 0 0px skyblue;
    width:50%;
    transition:0.1s;
}
.header{
  height:6vh;
  width:100%;
  background-color: #f2f3f5;
  display:flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.header-left{
  flex:1;
}
.header-menu{
  flex:1 1 56vw;
}
.header-right{
  flex:1;
  display:flex;
  flex-direction:row;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.logout{
  width:100px;
  outline:none;
  background-color:#ff5900d3;
  border:none;
  color:white;
  font-size: 1em;
  transition:0.2s;
  border-radius:6px;
}
a{
  text-decoration: none;
  font-size: 1.2em;
  transition:0.2s;
  color:black;
}
a:hover{
  cursor:pointer;
  color:black;
  border-bottom: 2px solid black;
  transition: 0.2s;
}
a:visited{
  color:black
}
}
</style>
