import { createStore } from 'vuex'

export default createStore({
  state: {
    name:'',
    username:'',
    organ_name:'',
    token:''
  },
  getters: {
    getUsername(state){
      return state.username
    }
  },
  mutations: {
    saveUser(state,{name,username,organ,token}){
      state.name = name;
      state.username = username;
      state.organ_name = organ;
      state.token = token;
    }
  },
  actions: {
    saveUser(context,{name,username,organ,token}){
      context.commit('saveUser',{name,username,organ,token});
    }
  },
  modules: {
  }
})
