<template>
  <div class="bg">
    <div class="left">
      <div class="main-panel" >
        <div class="top">
          <div @click="toLogin" style="border-right:1px solid #dfdfdf">登陆</div>
          <div @click="toReg">注册</div>
        </div>
        <div class="middle">
          <div>
            <label for="username">账号：</label><input v-model.trim="username" id="username" type="text">
          </div>
          <div>
            <label for="password1">密码：</label><input v-model.trim="password1" id="password1" type="password">
          </div>
          <div>
            <label for="password2">确认密码：</label><input v-model.trim="password2" id="password2" type="password">
          </div>
          <div>
            <label for="organ_code">机构码：</label><input v-model.trim="organ_code" id="organ_code" type="text">
          </div>
          <button @click="reg">注册</button>
        </div>
      </div>
      <div class="footer">
        <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022002373号-2</a>
      </div>
    </div>
    <div class="right">
    </div>
  </div>
</template>



<script>
export default {
  // name: "Register",
  data() {
    return {
      pageHeight: 0,
      pageWidth: 0,
      username:'',
      password1:'',
      password2:'',
      organ_code:''
    };
  },
  methods: {
    reg(){
        if (this.username&&this.password1&&this.password2&&this.organ_code){
            if(this.password2!=this.password1){
                alert('两次输入密码不一致！')
            }
            else{
                this.axios({
                  method:'post',
                  url:'https://kmail.esia.asia/user/v1/registeruser',
                  data:{
                    username:this.username,
                    password:this.password1,
                    organ_code:this.organ_code
                  }
                }).then(res=>{
                  if(res.data.status == 1){
                    this.$store.dispatch('saveUser',{
                      user:res.data.name,
                      username:res.data.username,
                      organ:res.data.organ,
                      token:res.data.token
                    })
                    localStorage.user = res.data.user
                    localStorage.username = res.data.username
                    localStorage.organ_name = res.data.organ
                    localStorage.token = res.data.token
                    this.$router.push('/express')
                  }
                  }).catch(err=>{console.log(err)})
            }
        }
        else{
            alert('请填写所有必填项。')
        }
    },
    toLogin(){
      this.$router.push('/login')
    },
    toReg(){
      this.$router.push('/register')
    }
  },
  mounted(){
    this.pageHeight = window.innerHeight;
    this.pageWidth = window.innerWidth
  },
  computed:{
    pageH(){
      return 'height:' + this.pageHeight + 'px';
    }
  }
};
</script>



<style scoped>
.bg{
  display: flex;
  width:100%;
  height:100vh;
  flex-direction: row;
}
.left{
  width:60%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.footer{
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer a{
  text-decoration: none;
}
.footer a:visited{
  color:#aeaeae
}
.right{
  background-color:skyblue;
  width:40%;
}
.main-panel{
  width:66%;
  border:1px solid #efefef;
  border-radius: 20px;
  box-shadow: 1px 1px 30px #dfdfdf;
}
.top{
  display: flex;
  justify-content: space-around;
}
.top div{
  height:50px;
  line-height: 50px;
  width:50%;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 0 0 red,0 0 0 blue,0 0 0 green, 0 4px 4px #dfdfdf;
}
.middle{
  margin-top:40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.middle div{
  margin-right:6em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height:4rem;
  line-height: 40px;
  width:80%;
}
.middle div input{
  margin:0;
  padding:0;
  width:60%;
  font-size:1em;
  height:2em;
  outline:none;
  border:1px solid #dfdfdf;
  border-radius: 0.4em;
}
.middle div input:focus{
  border:1px solid black;
}
button{
  margin-top:3em;
  margin-bottom: 3em;
  padding:0;
  width:60%;
  height:2.6em;
  background-color:#409EFF;
  color:white;
  font-size:1em;
  border:none;
  border-radius: 0.4em;
}


/* 小尺寸屏幕 */
@media (max-width: 768px){
  .bg{
  display: flex;
  width:100vw;
  height:100vh;
  flex-direction: row;
}
.left{
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.footer{
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer a{
  text-decoration: none;
}
.footer a:visited{
  color:#aeaeae
}
.right{
  display: none;
  background-color:skyblue;
  width:0;
}
.main-panel{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width:100vw;
  height: 100vh;
  border:1px solid #efefef;
  border-radius: 20px;
  box-shadow: 1px 1px 30px #dfdfdf;
}
.top{
  width: 100vw;
  display: flex;
  justify-content: space-around;
}
.top div{
  height:50px;
  line-height: 50px;
  width:50%;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 0 0 red,0 0 0 blue,0 0 0 green, 0 4px 4px #dfdfdf;
}
.middle{
  margin-top:40px;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.middle div{
  height:4rem;
  line-height: 40px;
  width:80vw;
}
.middle div input{
  margin:0;
  padding:0;
  width:60%;
  font-size:1em;
  height:2em;
  outline:none;
  border:1px solid #dfdfdf;
  border-radius: 0.4em;
}
.middle div input:focus{
  border:1px solid black;
}
button{
  margin-top:3em;
  margin-bottom: 3em;
  padding:0;
  width:60%;
  height:2.6em;
  background-color:#409EFF;
  color:white;
  font-size:1em;
  border:none;
  border-radius: 0.4em;
}
}

/* 超大屏幕 */
@media (min-width:1200px){
  .bg{
  display: flex;
  width:100%;
  height:100vh;
  flex-direction: row;
}
.left{
  width:60%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.footer{
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer a{
  text-decoration: none;
}
.footer a:visited{
  color:#aeaeae
}
.right{
  background-color:skyblue;
  width:40%;
}
.main-panel{
  width:46%;
  border:1px solid #efefef;
  border-radius: 20px;
  box-shadow: 1px 1px 30px #dfdfdf;
}
.top{
  display: flex;
  justify-content: space-around;
}
.top div{
  height:50px;
  line-height: 50px;
  width:50%;
  border-bottom: 1px solid #dfdfdf;
  box-shadow: 0 0 0 red,0 0 0 blue,0 0 0 green, 0 4px 4px #dfdfdf;
}
.middle{
  margin-top:40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.middle div{
  margin-right:6em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height:4rem;
  line-height: 40px;
  width:80%;
}
.middle div input{
  margin:0;
  padding:0;
  width:60%;
  font-size:1em;
  height:2em;
  outline:none;
  border:1px solid #dfdfdf;
  border-radius: 0.4em;
}
.middle div input:focus{
  border:1px solid black;
}
button{
  margin-top:3em;
  margin-bottom: 3em;
  padding:0;
  width:60%;
  height:2.6em;
  background-color:#409EFF;
  color:white;
  font-size:1em;
  border:none;
  border-radius: 0.4em;
}
}
</style>