import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAxios from 'vue-axios'
import axios from 'axios'
// 引入Element-Plus项目
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/dist/index.css'

const app = createApp(App)
app.use(VueAxios,axios)
.use(ElementPlus)
.use(ElementPlus, {locale: zhCn})
.use(store)
.use(router)
.mount('#app')
