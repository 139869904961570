<template>
    <div class="main">
        <div class="aside">
            <div class="send-menu">
                <div class="send-menu-title">寄件</div>
                <div class="send-menu-a"><a href="#/express">单个寄件</a></div>
            </div>
            <div class="send-list-menu">
                <div class="send-menu-title">寄件列表</div>
                <div class="send-menu-a"><a href="#/expresslists">寄件列表</a></div>
            </div>
            <div class="send-list-menu">
                <div class="send-menu-title">导出</div>
                <div class="send-menu-a"><a href="#/export">导出列表</a></div>
            </div>
            <div class="wx_qrcode">
                <img src="@/assets/qrcode.jpg" alt="">
            </div>
            <div class="footer">
                <a href="https://beian.miit.gov.cn/" target="_blank">滇ICP备2022002373号-2</a>
            </div>
        </div>
        <div class="content">
            <div>
                <el-config-provider :locale="locale">
                    <div class="demo-date-picker">
                        <div class="block">
                        <el-date-picker
                            v-model="choosevalue"
                            type="daterange"
                            start-placeholder="开始时间"
                            end-placeholder="截止时间"
                            :default-time="defaultTime"
                        />
                        </div>
                    </div>
                </el-config-provider>
                <br>
                {{formatchooseTime}}
                <br>
                <button @click="exportExpressList">导出寄件列表</button>
                <button @click="exportAssessorList">导出陪审列表</button>
            </div>
        </div>
    </div>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
    setup(){
        return{
            locale:zhCn,
        }
    },
    data(){
        return{
            choosevalue : '',
            defaultTime:([new Date(2024,1,1,0,0,0),new Date(2024,12,31,23,59,59)]),
        }
    },
    methods:{
        exportExpressList(){
            if (this.choosevalue)
                this.axios({
                    method:'post',
                    headers:{Authorization:localStorage.token},
                    url:'https://kmail.esia.asia/express/v1/export',
                    data:{
                        start:this.choosevalue[0].getTime()/1000,
                        end:this.choosevalue[1].getTime()/1000,
                    }
                })
                .then(res=>{
                    if (res.data.status){
                        alert('导出错误，请重试')
                    }else{
                        var now = new Date()
                        var month = now.getMonth()+1
                        var date = now.getFullYear() + '年' + month + '月' + now.getDate() + '日'
                        var h = now.getHours()
                        var m = now.getMinutes()
                        if ( h < 10 ){
                            h = '0' + now.getHours()
                        }
                        if ( m < 10 ){
                            m = '0' + now.getMinutes()
                        }
                        var time = h + ':' + m
                        let url = window.URL.createObjectURL(new Blob([res.data]))
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = url
                        link.setAttribute('download', date + ' ' + time + '.csv')
                        document.body.appendChild(link)
                        link.click()
                        // 释放URL对象所占资源
                        window.URL.revokeObjectURL(url)
                        // 用完即删
                        document.body.removeChild(link)
                    }
                })
                .catch(err=>{console.log(err);})
            else
                alert('请选择起止时间')
        },
        exportAssessorList(){
            if (this.choosevalue)
                this.axios({
                    method:'post',
                    headers:{Authorization:localStorage.token},
                    url:'https://kmail.esia.asia/psy/v1/export',
                    data:{
                        start:this.choosevalue[0].getTime()/1000,
                        end:this.choosevalue[1].getTime()/1000,
                    }
                })
                .then(res=>{
                    if (res.data.status){
                        alert('导出错误，请重试')
                    }else{
                        var now = new Date()
                        var month = now.getMonth()+1
                        var date = now.getFullYear() + '年' + month + '月' + now.getDate() + '日'
                        var h = now.getHours()
                        var m = now.getMinutes()
                        if ( h < 10 ){
                            h = '0' + now.getHours()
                        }
                        if ( m < 10 ){
                            m = '0' + now.getMinutes()
                        }
                        var time = h + ':' + m
                        let url = window.URL.createObjectURL(new Blob([res.data]))
                        let link = document.createElement('a')
                        link.style.display = 'none'
                        link.href = url
                        link.setAttribute('download', date + ' ' + time + '.csv')
                        document.body.appendChild(link)
                        link.click()
                        // 释放URL对象所占资源
                        window.URL.revokeObjectURL(url)
                        // 用完即删
                        document.body.removeChild(link)
                    }
                })
                .catch(err=>{console.log(err);})
            else
                alert('请选择起止时间')
        }
    },
    computed:{
        formatchooseTime(){
            if (this.choosevalue!=''){
                return '导出区间：'+ this.choosevalue[0].toLocaleString().replace(/:\d{1,2}$/,' ') + '- ' + this.choosevalue[1].toLocaleString().replace(/:\d{1,2}$/,' ')
            }
        }
    }
}
</script>

<style scoped>
.main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background-color: #f2f3f5;
    max-height: 94vh;
}
.aside{
    min-width: 10vw;
    max-width: 12vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.footer{
    font-size: 12px;
    width: inherit;
    text-align: center;
  position: absolute;
  bottom: 0;
  /* margin-bottom: 10px; */
  display:flex;
  flex-direction: column;
  align-items: center;
}
.footer a{
  text-decoration: none;
}
.footer a:visited{
  color:#aeaeae
}
.send-menu,.send-list-menu{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 20px;
    margin-left: 20px;
    width:8vw;
}
.send-menu-title{
    color:rgb(106, 106, 106)
}
.send-menu-a{
    background-color: #9f9f9f61;
    height:56px;
    line-height: 56px;
    font-size: 1.2rem;
    border-radius: 6px;
    margin-top: 10px;
    width:inherit;
    text-align:left
}
.send-menu-a a:visited{
    outline: none;
    text-decoration: none;
    color:#333333
}
.send-menu-a a{
    outline: none;
    text-decoration: none;
    color:#333333
}
.content{
    flex:1;
    height: 94vh;
    padding-top: 120px;
    width:80vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 20px 20px 0 0;
}
.block > *{
    display: inline;
    box-sizing: border-box;
}
.pick_time{
    display:flex;
    flex-direction: row;
}
.wx_qrcode{
    width:inherit;
    height:inherit;
}
.wx_qrcode img{
    width: 80%;
    height: inherit;
}
</style>