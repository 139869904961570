<template>
    <div class="mobile">手机端请使用微信搜索“收发猫”小程序进行快递/陪审信息登记</div>
    <div class="main">
        <div class="aside">
            <div class="psy-list">
                <table>
                    <tbody>
                        <tr class="aside-table-header">
                            <td>姓名</td>
                            <td>性别</td>
                            <td>电话</td>
                            <td>剩余</td>
                        </tr>
                        <tr v-for="(item,index) in PSYlists" :key="index" class="check-psy">
                            <td>{{item.name}}</td>
                            <td>{{item.sex}}</td>
                            <td>{{item.phone}}</td>
                            <td>{{30 - item.times}}</td>
                        </tr>
                    </tbody>
                    <tfoot></tfoot>
                </table>
            </div>
        </div>
        <div class="main-content">
            <div class="input-group">
                <input v-model.trim="add.ah" type="text" placeholder='案号，输入"22.888试试！"' @blur="ah_blur">
                <div class="radio">
                    <span>法庭：</span>
                    <el-radio-group v-model="add.ft" size="small" @change="change">
                        <el-radio-button label="1" />
                        <el-radio-button label="2" />
                        <el-radio-button label="3" />
                        <el-radio-button label="4" />
                        <el-radio-button label="5" />
                        <el-radio-button label="6" />
                        <el-radio-button label="7" />
                        <el-radio-button label="8" />
                    </el-radio-group>
                </div>
                <div class="time">
                    <el-config-provider :locale="locale">
                        <div class="demo-date-picker">
                            <div class="block">
                            <el-date-picker
                                v-model="add.ktsj"
                                type="datetime"
                                placeholder="Select date and time"
                                :default-time="defaultTime"
                            />
                            </div>
                        </div>
                    </el-config-provider>
                </div>
                <input v-model.trim="add.psy1" type="text" placeholder="陪审员1">
                <input v-model.trim="add.psy2" type="text" placeholder="陪审员2">
                <input v-model.trim="add.sjy" type="text" placeholder="书记员">
                <button @click="addPSInfo">添加陪审信息</button>
            </div>
            <div class="PSlist">
                <table>
                    <tr>
                        <td>案号</td>
                        <td>开庭时间</td>
                        <td>陪审员1</td>
                        <td>陪审员2</td>
                        <td>书记员</td>
                        <td>抽取人</td>
                        <td>抽选时间</td>
                    </tr>
                    <tr class="PSlist-item" v-for="(item,index) in PSlists[0]" :key="index">
                        <td>{{item.ah}}</td>
                        <td>{{item.ktsj}}</td>
                        <td>{{item.psy1}}</td>
                        <td>{{item.psy2}}</td>
                        <td>{{item.sjy}}</td>
                        <td>{{item.cqr}}</td>
                        <td>{{formatTime(item.created_time)}}</td>
                    </tr>
                </table>
            </div>
            <div class="footer">
                <button v-if="previousPage" @click="pre">上一页</button>
                <button v-if="nextPage" @click="next">下一页</button>
            </div>
        </div>
    </div>
</template>



<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default{
    setup(){
        return{
            locale:zhCn,
        }
    },
    data(){
        return{
            defaultTime : new Date(2024,1,1,12,30,0),
            username:'',
            PSlists:[],
            PSYlists:[],
            previousPage:'',
            nextPage:'',
            add:{
                ah:'(2024)云2328',
                ft:'',
                ktsj:'',
                psy1:'',
                psy2:'',
                sjy:'',
            }
        }
    },
    methods:{
        change(e){
            this.add.ft = e
        },
        dateFormat(time, fmt) {
            var o = {
                "M+": time.getMonth() + 1,
                "d+": time.getDate(),
                "h+": time.getHours(),
                "m+": time.getMinutes(),
                "s+": time.getSeconds(),
                "q+": Math.floor((time.getMonth() + 3) / 3),
                "S": time.getMilliseconds()
            };
            if (/(y+)/.test(fmt))
                fmt = fmt.replace(RegExp.$1, (time.getFullYear() + "").substr(4 - RegExp.$1.length));
            for (var k in o)
                if (new RegExp("(" + k + ")").test(fmt))
                    fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            return fmt;
        },
        // 添加陪审信息
        addPSInfo(e){
            if (this.add.psy1==""&&this.add.psy2==""){
                alert('请输入陪审员姓名')
            }
            if (this.add.ft == ''){
                alert('请选择法庭')
            }
            if(this.add.psy1!=''||this.add.psy2!=''){
                for(var i in this.PSYlists){
                    if (this.PSYlists[i].name == this.add.psy1){
                        if (this.PSYlists[i].times == 30){
                            alert(this.add.psy1 + "已达到最大抽取次数。")
                            this.add.psy1 = ''
                            break;
                        }
                    }
                }
                for(i in this.PSYlists){
                    if (this.PSYlists[i].name == this.add.psy2){
                        if (this.PSYlists[i].times == 30){
                            alert(this.add.psy2 + "已达到最大抽取次数。")
                            this.add.psy2 = ''
                            break;
                        }
                    }
                }
                this.doAdd()
            }
        },
        doAdd(){
            this.axios({
                method:'post',
                headers:{Authorization:localStorage.token},
                url:'https://kmail.esia.asia/psy/v1/addpsinfo',
                data:{
                    ah:this.add.ah,
                    ft:this.add.ft,
                    ktsj:this.dateFormat(this.add.ktsj, "yyyy-MM-dd hh:mm"),
                    psy1:this.add.psy1,
                    psy2:this.add.psy2,
                    sjy:this.add.sjy,
                }
            }).then(res=>{
                if (res.data.status == 1){
                    this.loadPSLists()
                    this.loadPSYLists()
                    this.add.ah = '(2024)云2328'
                    this.add.ft = ''
                    this.add.ktsj = ''
                    this.add.psy1 = ''
                    this.add.psy2 = ''
                    this.add.sjy = ''
                }else if (res.data.status == -1){
                    alert(res.data.msg)
                }else{
                    alert(res.data)
                }
            }).catch(err=>{console.log(err)})
        },
        // 加载陪审信息列表
        loadPSLists(){
            this.axios({
                method:'post',
                headers:{Authorization:localStorage.token},
                url:'https://kmail.esia.asia/psy/v1/showallpsinfo'
            }).then(res=>{
                this.PSlists[0] = res.data.results
                // this.count = res.data.count
                this.nextPage = res.data.next
                this.previousPage = res.data.previous
            }).catch(err=>{console.log(err)})
        },
        // 加载陪审员列表
        loadPSYLists(){
            this.axios({
                method:'post',
                headers:{Authorization:localStorage.token},
                url:'https://kmail.esia.asia/psy/v1/showallpsy'
            }).then(res=>{
                this.PSYlists = []
                for(let item in res.data){
                    this.PSYlists.push(res.data[item])
                }
            }).catch(err=>{console.log(err)})
        },
        // 上一页
        pre(){
            this.axios({
                method:'post',
                headers:{Authorization:localStorage.token},
                url:this.previousPage
            }).then(res=>{
                this.PSlists[0] = res.data.results
                // this.count = res.data.count
                this.nextPage = res.data.next
                this.previousPage = res.data.previous
            }).catch(err=>{console.log(err)})
        },
        // 下一页
        next(){
            this.axios({
                method:'post',
                headers:{Authorization:localStorage.token},
                url:this.nextPage
            }).then(res=>{
                this.PSlists[0] = res.data.results
                // this.count = res.data.count
                this.nextPage = res.data.next
                this.previousPage = res.data.previous
            }).catch(err=>{console.log(err)})
        },
        ah_blur(){
            if(this.add.ah.charAt(this.add.ah.length-1)!="号"&&this.add.ah!=""){
                this.add.ah+="号"
            }
        },
        formatTime(value){
            var v = value
            return v.slice(0,4) + '年' + v.slice(5,7)+ '月' + v.slice(8,10) + '日' + v.slice(11,19)
        }
    },
    watch:{
        'add.ah'(oldV,newV){
            if(oldV.length==5&&(oldV[4]=="."||oldV[4]=="。")){
                this.add.ah = "(" + oldV.slice(0,4) + ")云2328民初"
            }else if(oldV.length ==3&&(oldV[2]=="."||oldV[2]=="。")){
                this.add.ah = "(20" + oldV.slice(0,2) + ")云2328民初"
            }else if(oldV.length==6&&(oldV[0]=="（"||oldV[5]=="）")){
                this.add.ah = "(" + oldV.slice(1,5) + ")云2328民初"
            }else if(oldV[4]=="民"||oldV[4]=="行"||oldV[4]=="刑"){
                if (oldV[4]=="民"){this.add.ah = "(" + oldV.slice(0,4) + ")云2328民初" + oldV.slice(4,oldV.length-1)}
                if (oldV[4]=="行"){this.add.ah = "(" + oldV.slice(0,4) + ")云2328行初" + oldV.slice(4,oldV.length-1)}
                if (oldV[4]=="刑"){this.add.ah = "(" + oldV.slice(0,4) + ")云2328刑初" + oldV.slice(4,oldV.length-1)}
            }
        },
    },
    mounted(){
        this.loadPSLists()
        this.loadPSYLists()
    }
}
</script>



<style scoped>
.mobile{
    display: none;
}
.main{
    height:94vh;
    /* padding-top:6vh; */
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.aside{
    width:24vw;
    /* flex:2.5; */
    margin-right: 0;
    margin-bottom: 200px;
    height:94vh;
    text-align: center;
    background-color: #f2f3f5;
}
.main-content{
    flex:1;
    /* width:78vw; */
    display:flex;
    height:93vh;
    flex-direction:column;
    border-radius: 10px 10px 0 0 ;
    background-color: white;
}
table{
    width:100%;
}
.aside-table-header td:nth-child(2){
    width:2em;
}
.psy-list{
    overflow-y: auto;
    height:94vh;
    width:22vw;
    /* box-shadow: 0px 0px 20px #fce38a; */
}
.PSlist-item td{
    border-bottom:1px dotted #afafaf;
}
.input-group{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top:10px;
    width:100%;
    height:3em;
}
.input-group input:nth-child(1){
    width:170px;
    margin-right:4px;
}
.input-group input:nth-child(2){
    width:200px;
    margin-right:4px;
}
.input-group input:nth-child(3){
    width:60px;
    margin-right:4px;
}
.input-group input:nth-child(4){
    width:60px;
    margin-right:4px;
}
.input-group input:nth-child(5){
    width:60px;
    margin-right:4px;
}
.input-group input,button{
    margin:0;
    padding:0;
    height:3em;
    font-size: 0.8em;
    border:none;
}
.input-group input{
    border:1px solid #dfdfdf;
    text-align: center;
}
.input-group button{
    background-color: #95e1d3;
    border:1px solid #95e1d3;
    color:black;
    padding:0 1em 0 1em;
    transition: 0.3s;
}
.input-group button:hover{
    background-color: #95e1d3;
    border:1px solid #95e1d3;
    color:black;
    padding:0 1em 0 1em;
    box-shadow: 0 0 10px #95e1d3;
    transition: 0.3s;
}
.PSlist{
    width:100%;
    overflow-y: auto;
}
.PSlist tr:nth-child(1){
    width:170px;
}
.PSlist tr:nth-child(1) td{
    border:1px dotted #afafaf;
}
.PSlist tr:nth-child(2){
    width:220px;
}
.PSlist tr:nth-child(3){
    width:60px;
}
.PSlist tr:nth-child(4){
    width:60px;
}
.PSlist tr:nth-child(5){
    width:60px;
}
.footer{
    height:4vh;
}
.check-psy{
    transition:0.4s;
    height:30px;
    line-height:30px;
}
.check-psy td{
    border:1px dotted black;
}
.check-psy:hover{
    background-color:#95e1d3;
    color:black;
    transition:0.4s;
}
input{
    outline:none;
    border-radius:4px;
    transition:0.4s;
}
input:hover{
    border:1px solid #95e1d3;
    border-radius:4px;
    box-shadow: 0 0 4px #95e1d3;
    transition:0.4s;
}
input:focus{
    border:1px solid #95e1d3;
    border-radius:4px;
}
.time{
    display: inline-block;
}
.radio{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width:300px;
    box-sizing: border-box;
}


/* 手机端 */
@media (max-width: 768px) {
.main{
    display: none;
}
.mobile{
    display: block;
    position: fixed;
    width:100vw;
    height:100vh;
    left: 0;
    top: 50%;
    text-align: center;
}
}
</style>